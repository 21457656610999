(() => {
	const navigationLinksUrl = themeDisplay.getURLsForAnalyticsTracking();
	const navigationLinks = Array.from(document.querySelectorAll('a'));

	if (navigationLinks) {
		const filteredAnchorTags = navigationLinks.filter((anchor) => {
			const parsedHref = anchor.href.replace(
				themeDisplay.getMagellanDefaultURL(),
				''
			);

			return navigationLinksUrl.includes(parsedHref);
		});

		
		filteredAnchorTags.forEach((anchor) => {
			const attributeValue = anchor.href.replace(
				`${themeDisplay.getMagellanDefaultURL()}/`,
				''
			);

			anchor.setAttribute('data_ga_id', attributeValue);
		});
	}

	window.dataLayer.push({
		event: 'page_view',
		eventModel: {
			page_location: window.location.href,
			page_title: document.title,
			page_referrer: document.referrer,
			account_name: themeDisplay.getAccountName(),
			user_id: themeDisplay.getUserId(),
			ccid: themeDisplay.getAccountUuid(),
			login_status: themeDisplay.isSignedIn(),
			user_product: themeDisplay.getProductName(),
		}
	});
})();
